import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel'
import HtmlParser from 'react-html-parser';
import {checkData} from "../../helper";

function Banner({data}) {

    return (
        <div className="tekrevol_banner">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="mt-auto mb-auto">
                        <Carousel indicators={true}>
                            {data?.slider?.map((item, i) => (
                                <Carousel.Item key={i}>
                                    <div className="example1" data-text="Experience">
                                        <h1>{HtmlParser(checkData(item, 'title'))}</h1>
                                    </div>
                                    <p>{HtmlParser(item.description)}</p>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Banner;